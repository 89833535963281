/* Imagen dentro del card */
.reporte-img {
    cursor: pointer;
    border-radius: 15px;
    /* Bordes redondeados */
    width: 100%;
    /* Ocupará todo el ancho de su contenedor */
    height: auto;
}

/* Ícono (lupa) superpuesto */
.reporte-img-lupa {
    position: absolute;
    bottom: 15px;
    right: 15px;
    font-size: 24px;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    pointer-events: none;
    /* para que el clic siga siendo en la imagen */
}

/* Contenedor del modal (pantalla completa) */
.image-modal-container {
    position: relative;
    height: 100%;
}

/* Botón para cerrar el modal */
.close-image-button {
    position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  
  /* Hacemos que sea un círculo y definimos tamaño */
  width: 40px;
  height: 40px;
  --border-radius: 50%; /* círculo */
  
  /* Centramos el icono dentro del botón */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Variables para el IonButton */
  --background: #000;           /* fondo negro */
  --background-hover: #333;     /* fondo negro más claro al hover */
  --background-activated: #555; /* fondo negro aún más claro al touch/click */
  --background-focused: #555;
  
  --color: #fff;                /* ícono blanco */
  --padding-start: 0;           /* quita padding extra si deseas */
  --padding-end: 0;
}

/* Imagen mostrada en el modal */
.modal-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Contenedor para la imagen y el ícono */
.container-img-with-icon {
    position: relative;
    display: inline-block;
    /* Si quieres que se comporte como bloque a 100% del contenedor padre, cambia a 'display: block' */
}

.img-custom-modal{
  
    --background: rgba(0, 0, 0, 0.8);
    /* negro con 80% de opacidad */
}

