
.txt-titulo-informe {
  max-width: 100%;
  overflow: hidden;
  display: block;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

