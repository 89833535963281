.encabezado-rubro {

    min-height: 5em;
    position: absolute;
    padding-bottom: 2em;
    /* left: 16px; */
    z-index: 1;
    /* text-align: center; */

    width: 100%;
    background-color: #e6dcff;
}

.icon-card-title {
    width: 1.8em;
    height: 1.8em;
    padding-left: 16px;
    color: #474748;
}

.titulo-rubro {
    padding-left: 10px;
    margin: 0;
    font-size: 1.125rem !important;
}

.card-content-box {
    border-radius: 20px !important;
    /* Bordes redondeados solo para el contenido */
    margin-top: 3em;
    /* Espacio para el encabezado */
    background-color: #fefeff;
    z-index: 1;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
    /* Sombra hacia arriba */
}

.card-bg-color {
    background-color: rgb(255, 0, 0) !important;
}