.ion-margin-bottom {
    margin-bottom: 16px; /* Ajusta según necesites */
}

.create-form-label {
    display: block; /* Asegura que el label ocupe toda la línea */
    font-weight: 500; /* Aplica el peso de la fuente */
    font-size: 1.5em; /* Opcional: ajusta el tamaño */
    letter-spacing: 0.5px;/* Opcional: ajusta el color */
}

.ion-input-upload{
    --padding-top: 15px;
}

.icon-upload-button{
    font-size: 35px;
    color: #3891ff
}

.spn-upload-image{
    text-transform: capitalize;
    color: #635c66;
    font-weight: 500;
    padding-top: 5px;
    font-size: .9em;
}

.spn-upload-subtitle{
    text-transform: capitalize;
    color: #474747;
    font-weight: 300;
    padding-top: 15px;
    font-size: .8em;
}

.ion-upload-img-button{
    margin-top: 15px;
    height: 7rem;
    --border-radius: 5px;
    --background: #e1e6ef;
    --box-shadow: none;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    border: 2px dashed #3880ff; 
    border-radius: 5px;
    padding: 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #9b9a9d; 
    font-size: 1rem; 
    transition: background-color 0.3s ease, border-color 0.3s ease; 
}

.ion-upload-img-button:hover {
    --background: #d9d6dc; 
    border-color: #7a797d; 
    cursor: pointer;
}

.d-flex-items-vertical {
    display: flex;           
    flex-direction: column;    
    justify-content: center;    
    align-items: center; 
}

.required-asterisk {
    color: red;
    font-weight: bold;
    margin-left: 0.15rem; /* Separación entre el texto y el asterisco */
    font-size: 1.2rem; /* Ajusta el tamaño del asterisco */
}

.btn-guardar {
    margin-top: 2rem; /* Mayor separación vertical */
    text-transform: capitalize; /* Texto en minúscula */
    font-size: 1.2rem; /* Ajusta el tamaño del texto si es necesario */
}

.create-form-label-helper {
    display: flex;
    flex-direction: column; /* Asegura que el <label> y <small> estén en columna */
    gap: 15px; /* Espaciado entre <label> y <small> */
}

.txt-label-helper{
    font-weight: 500; /* Aplica el peso de la fuente */
    font-size: 1.5em; /* Opcional: ajusta el tamaño */
    letter-spacing: 0.5px;
}

.helper-text {
    font-size: 0.875em;
    color: #6c757d; /* Un color gris para que no distraiga */
}

.create-form-input{
    margin-top: 15px;
}
.image-preview-container {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.image-preview {
    width: 100%;
    border-radius: 5px;
}

.delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
 
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.552);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
}

.delete-icon:hover {
    transform: scale(1.2);
}
.select-image-custom-modal {
  --border-radius: 20px;
  --background: #ffffff; /* Puedes personalizar el color de fondo */
  --box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Para un efecto más estilizado */
}

.select-image-custom-modal .modal-wrapper {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden; /* Asegura que el contenido no se salga de los bordes redondeados */
}