
/*   .service-card {
    border: 1px solid #a6a5a7; 
    border-radius: 20px; 
    padding: 16px; 
    margin-bottom: 16px; 
   
 
}
 */
 
.services-title {
    display: inline-block; /* Ajusta el ancho al contenido */
    padding: 8px 16px; /* Espaciado interno */
    border: 1px solid #a482ae; /* Borde sólido */
    border-radius: 20px; /* Esquinas redondeadas */
    font-size: 0.75rem; /* Tamaño de fuente */
    text-transform: uppercase;
    text-align: center; /* Centrado del texto */
   letter-spacing: 1px;
    color: #a482ae; /* Color del texto */
   
    margin: 0 auto; /* Centra horizontalmente */
    transition: background-color 0.2s, color 0.2s;
}