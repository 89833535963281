.ion-icon-visita-item{
    font-size: 1.8rem;
    color: #6f1dda;
    background-color: #f4f1fb;
    border-radius: 30%; 
    padding: 15px 15px;
    margin-left: 0;
    margin-right: 10px; 
}
.ion-icon-visita-forward{
    font-size: 1.5rem;
    color: #6f1dda;
    margin-left: auto;
}
.obra-item-box{
    width: 100%;
}  

.obra-item-text {
    display: flex;
    flex-direction: column; /* Alinea el título y subtítulo verticalmente */
  }