.item-button{
    --padding-end: 16px;
}
.item-border-none{
    --inner-border-width: 0 0 0 0;
}
.icon-item-option{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    margin-right: 10px; /* Espaciado entre el ícono y el texto */
    color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.7);
}

/* Estilo específico para el botón de eliminar */
.item-button.delete {
    color: #e63946; /* Color rojo para el texto */
}

.item-button.delete .icon-item-option {
    color: #e63946; /* Color rojo para el ícono */
}
.item-button.delete:hover .icon-item-option {
    color: #b71c1c; /* Color rojo para el ícono */
}

/* Cambiar color del label en hover */
.item-button.delete:hover .label-delete {
    color: #b71c1c; /* Rojo oscuro para la etiqueta en hover */
}