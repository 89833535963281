.ion-grid-login {
  width: 100%;
  height: 100%;
}
.ion-button-show-hide {
  pointer-events: auto;
  position: relative;
  z-index: 10;
}
.input-login{
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
.ion-login-content {
  --background: var(--ion-background-color, #edf0f5);
}
.ion-login-item {
  font-weight: 500;
  /*  font-size: 1.0rem; */
  /* --border-radius: 34px; */
  margin-top: 10px;
  margin-bottom: 5px;
  --border-width: 0px 0px 1px 0px;
  --padding-start: 0px;
  --padding-end: 0px;

  /* --inner-border-width: 0px 0px 0px 0px; */
  /*  --padding-top: 0px; */
  --background: var(--ion-item-background, var(--ion-background-color, none));

  --border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.2)))
  );
}
.error-form {
  display: flex;
  align-items: center;
  color: red; /* Cambia este color al que prefieras para los mensajes de error */
  font-size: .8rem !important;
}

.error-form ion-icon {
  margin-right: 0.5rem; /* Espacio entre el icono y el mensaje */
}
.ion-login-input {
  font-weight: 500;
  --placeholder-opacity: 0.5;
  /* --border-color: var(
    --ion-item-border-color,
    var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.25)))
  ); */
}
.col-login-form {
  background-color: none;
  padding-top: 5em;
}
.ion-row-login {
  height: 100%;
}
.ion-logo-login {
  display: inline-block;
  height: 74px;
  width: 148px;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(../../../public/assets/logo-obrabit.svg);
  background-image: url(../../../public/assets/logo-obrabit.svg);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;

  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-repeat: no-repeat;
}
.align-center-logo-login {
  text-align: center;
  padding-bottom: 50px;
}
.ion-button-login {
  min-height: 56px;
  margin-top: 1em;
  text-transform: capitalize!important;
  font-size: 1.2rem;
}
.ion-padding-login {
  padding-inline-start: var(--ion-padding, 25px);
  padding-inline-end: var(--ion-padding, 25px);
}
.lbl-login-form{
  transform: translateY(50%) scale(0.9)!important;
}
