.ion-card-edificio {
    border-style: solid;
    border-width: 1px;
    border-color: #edeced;
    box-shadow: none;
    margin-inline: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 20px;
}

.ion-card-edificio:hover {

    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

}

.img-card-archivo {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
}

.img-box-archivo {
    min-height: 200px;
   padding-top: 10px;
}
.card-header-archivo{
    padding: 5px 0px;
}



.svg-icon-archivo {
    font-size: 1.5em;
    color: #f10d0d;
    display: block;
}
.txt-header-archivo{
    max-width: 80%;
    overflow: hidden;
    display: block;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    font-weight: 600 !important;
}
.spn-txt-archivo{
    width: 100%;
    
}
.spn-icon-archivo{
    padding-right: 15px;

}