.list-card-toolbar {
 
  padding: 0 16px;
  min-height: 45px;
  position: relative; /* Relativo para ajustar elementos internos */
}

.card-ellipsis-btn {
  --color: #454545;
 
  --padding-start: 10px;
  --padding-end: 10px;
  display: flex; /* Permite centrar el ícono dentro del botón */
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
  position: absolute; /* Para ajustarlo al borde */
 
  right: 5px; /* Ajusta el espacio al borde derecho */
 
  z-index: 10;
}

.card-ellipsis-btn ion-icon {
  font-size: 18px; /* Tamaño del ícono */
  margin: 0; /* Sin espacio adicional */
}
