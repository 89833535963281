.ion-card-descripcion {
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #dedddf;
    --background: #fefeff;
    box-shadow: none;
    margin-inline: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
}

/*cards descripcion font size */
.ion-card-descripcion-content p {
    font-size: 1rem !important;
    color: #454545;
}

.ion-card-descripcion-content h4 {
    font-size: 1.125rem !important;
}
.ion-card-descripcion-content h4.card-detail-title {
    font-size: 1.25rem !important; /* Cambia el tamaño o cualquier otra propiedad */
}
/*cards escritorio font size */
.ion-card-escritorio-content p {
    font-size: 1rem !important;
    color: #454545;
}

.ion-card-escritorio-content h4 {
    font-size: 1.125rem !important;
}

.ion-card-escritorio-content h1 {
    font-size: 1.25rem !important;
}

.ion-card-escritorio-content h2 {
    font-size: 1.325rem !important;
}

/*cards list font size */
.ion-card-list-content p {
    font-size: 1rem !important;
    color: #454545;
}

.ion-card-list-content h4 {
    font-size: 1.125rem !important;
}

/*cards list item font size */
.ion-card-item-content p {
    font-size: 0.875rem !important;
    color: #454545;
}

.ion-card-item-content h4 {
    font-size: 1.125rem !important;
}

.text-small {
    font-size: 0.812rem !important;
    color: #454545;
}

.ion-icon-menu {
    font-size: 24px;
    color: #ffffff;
    background-color: var(--menu-icon-background-color);
    border-radius: 50%;
    padding: 10px 10px;
}



.ion-card-menu {
    border-radius: 20px;
    --background: #fefeff;
    border-style: solid;
    border-width: 1px;
    border-color: #dce0e7;
    box-shadow: none;
    margin-inline: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.ion-card-menu:hover {
    --menu-icon-background-color: #880d73;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

}

/* .card-content-md{
    padding-top: 10px;
  } */
.ion-card-list {
    border-style: solid;
    border-width: 1px;
    border-color: #dce0e7;
    box-shadow: none;
    margin-inline: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 20px;
    --background: #fefeff;
}

.ion-card-list:hover {

    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

}

.img-card-list {
    width: 100%;
    height: 200px;
    object-fit: cover;
    padding: 5px 5px;
    border-radius: 20px;
}

.img-card-box {
    min-height: 200px;
}

.txt-titulo-list-item {
    max-width: 100%;
    overflow: hidden;
    display: block;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.font-dark-grey {
    color: #454545;
}

.align-items-center {
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-justify {
    text-align: justify;
}
.font-weight-600 {
    font-weight: 600 !important;
}
.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-12 {
    font-size: 0.75rem !important;
    /* 12px / 16px */
}

.font-13 {
    font-size: 0.8125rem !important;
    /* 13px / 16px */
}

.font-14 {
    font-size: 0.875rem !important;
    /* 14px / 16px */
}

.font-16 {
    font-size: 1rem !important;
    /* 16px / 16px */
}

.font-18 {
    font-size: 1.125rem !important;
    /* 18px / 16px */
}

.font-20 {
    font-size: 1.25rem !important;
    /* 20px / 16px */
}

.font-22 {
    font-size: 1.375rem !important;
    /* 22px / 16px */
}

.font-bold {
    font-weight: 600 !important;
    letter-spacing: 0.0125em;
}


.float-right {
    float: right;
}

.float-left {
    float: left;
}

.d-inline-block {
    display: inline-block;
}

.m-0 {
    margin: 0 !important;
}

.mr-1 {
    margin-right: 5px;
}

.mr-2 {
    margin-right: 10px !important;
}

.ml-2 {
    margin-left: 10px !important;
}

.ml-3 {
    margin-left: 15px !important;
}

.mb-2 {
    margin-bottom: 10px !important;
}

.mb-3 {
    margin-bottom: 15px !important;
}
.mb-4 {
    margin-bottom: 20px !important;
}
.mt-1 {
    margin-top: 5px !important;
}

.mt-2 {
    margin-top: 10px !important;
}

.mt-3 {
    margin-top: 15px !important;
}

.mt-4 {
    margin-top: 20px !important;
}

.mb-1 {
    margin-bottom: 5px !important;
}

.ml-auto {
    margin-left: auto;
}

.h-100 {
    min-height: 30px !important;
    height: 100%;
}


.ion-grid-grey {
    background-color: #edf0f5;
    width: 100%;
}

.no-indent {
    padding-left: 1rem;
    margin-left: 0;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.ion-content-grey {
    --background: var(--ion-background-color, #edf0f5);
}

.border-header {
    border-bottom: none;
}

.mr-1 {
    margin-right: 5px;
}

.d-flex {
    display: flex !important;
}

.d-inline-block {
    display: inline-block;
}

.flex-grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    /* Espacio entre elementos si es necesario */
}

.center-content {
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100vh;
}


.tab-button-det {
    --color-selected: #10001d;
    max-width: none !important;
}

.badge-background-det {
    --background: #10001d !important;
    overflow: hidden;
    white-space-collapse: collapse;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 3em;
}

.tab-selected {
    border-top: solid !important;
    border-top-width: .25em !important;
}

.dashboard-row {
    padding-inline-start: var(--ion-grid-column-padding-md, var(--ion-grid-column-padding, 10px));
    padding-inline-end: var(--ion-grid-column-padding-md, var(--ion-grid-column-padding, 10px));
}

.status-bar {
    background-color: #edf0f5 !important;
    /* Establecer el color de fondo de la barra de estado */
}

.status-bar-light {
    color: #edf0f5 !important;
    /* Establecer el color del texto en un tema claro */
}

.status-bar-dark {
    color: #edf0f5 !important;
    /* Establecer el color del texto en un tema oscuro */
}

.item-menu {
    --border-style: none;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .04em;
}

.icon-menu {
    margin-inline-end: 18px;
    --ionicon-stroke-width: 40px;
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    color: #1d1d1d;
}

.menu-toolbar {
    --ion-toolbar-background: #fff;
    height: 71px;
}

.ion-logo-splitPane {
    margin-left: 16px;
    display: inline-block;
    height: 31px;
    width: 117px;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    background: url(../../public/assets/logo-obrabit-menu-5.svg);
    background-image: url(../../public/assets/logo-obrabit-menu-5.svg);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;

    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-repeat: no-repeat;
}

.services-toolbar {
    --padding-start: 20px;
    --padding-end: 20px;
}

.login-toolbar-btn {
    --color: #474748;
    text-transform: capitalize;
}

.ion-logo-servicios {
    display: inline-block;
    height: 35px;
    width: 149px;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    background: url(../../public/assets/logo-obrabit-toolbar.svg);
    background-image: url(../../public/assets/logo-obrabit-toolbar.svg);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;

    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-repeat: no-repeat;
}



@media (max-width: 768px) {

    /*cards descripcion font size */
    .ion-card-descripcion-content p {
        font-size: 1.125rem !important;
        color: #454545;
    }

    .ion-card-descripcion-content h4 {
        font-size: 1.2rem !important;
    }
    .ion-card-descripcion-content h4.card-detail-title {
        font-size: 1.25rem !important; /* Cambia el tamaño o cualquier otra propiedad */
    }
    /*cards escritorio font size */
    .ion-card-escritorio-content p {
        font-size: 1rem !important;
        color: #454545;
    }

    .ion-card-escritorio-content h4 {
        font-size: 1.125rem !important;
    }

    .ion-card-escritorio-content h1 {
        font-size: 1.25rem !important;
    }

    .ion-card-escritorio-content h2 {
        font-size: 1.4rem !important;
    }

    /*cards list font size */
    .ion-card-list-content p {
        font-size: 1rem !important;
        color: #454545;
    }

    .ion-card-list-content h4 {
        font-size: 1.125rem !important;
    }

    /*cards list item font size */
    .ion-card-item-content p {
        font-size: 0.875rem !important;
        color: #454545;
    }

    .ion-card-item-content h4 {
        font-size: 1.125rem !important;
    }

    .text-small {
        font-size: 0.812rem !important;
        color: #454545;
    }
}