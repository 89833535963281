.img-visita-item-box {
  min-width: 100px;
  height: 100px;
  overflow: hidden;
}

.img-visita-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.visitas-item-btn {
  --box-shadow: none !important;
  text-transform: capitalize !important;
}

.content-visita-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100px;
}

.icon-visita-item {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* Tamaño del ícono */
  font-size: 2rem;
  color: #6f1dda ;
}