  .escritorio-item-card-content {
    min-height: 100% !important;
  }

  .ion-icon-menu-detalles {
    font-size: 24px;
    color: #ffffff;
    background-color: #6f1dd9;
    border-radius: 50%;
    padding: 10px 10px;
}