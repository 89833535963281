.ion-icon-informe-item{
    font-size: 1.8rem;
    color: #ff7739;
    background-color: #f7f0f4;
    border-radius: 30%; 
    padding: 20px 20px;
    margin-left: 0;
    margin-right: 10px; 
}
.ion-icon-informe-forward{
    font-size: 1.8rem;
    color: #ff7739;
    margin-left: auto;
}
.informe-item-box{
    width: 100%;
}  

.informe-item-text {
    display: flex;
    flex-direction: column; /* Alinea el título y subtítulo verticalmente */
  }